<template>
  <div class="about">
    <div class="thumbnail-container">
      <img src="../assets/images/about_cover.png" />
    </div>
    <div class="container" style="padding-top:40px">
      <div>
        <div class="about_card card1">
          <h2 class="color-primary about_title title_font d-none d-sm-block">
            จะทำอย่างไร
          </h2>
          <h3 class="color-primary about_title d-block d-sm-none">
            จะทำอย่างไร
          </h3>
          <div class="about_captions d-none d-sm-block">
            <h4>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
              maxime amet officia. Qui natus mollitia cumque, quibusdam ipsam
              cupiditate voluptates, repudiandae, consequuntur molestiae facere
              consequatur dolore quisquam quis ullam iusto!Lorem
            </h4>
          </div>
          <div class="d-block d-sm-none">
            <p style="margin:30px 5px 0px 5px;">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
              maxime amet officia. Qui natus mollitia cumque, quibusdam ipsam
              cupiditate voluptates, repudiandae, consequuntur molestiae facere
              consequatur dolore quisquam quis ullam iusto!Lorem
            </p>
          </div>
          <div class="bluebox"></div>
        </div>
      </div>
    </div>
    <div class="container" style="padding-top:10px">
      <div class="about_card card2">
        <h2
          class="color-primary about_title title_font d-none d-sm-block"
          style="margin-top:60px"
        >
          ที่มาและความสำคัญของโครงการ
        </h2>
        <h3 class="color-primary about_title d-block d-sm-none">
          ที่มาและความสำคัญของโครงการ
        </h3>
        <div class="about_captions d-none d-sm-block">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
            maxime amet officia. Qui natus mollitia cumque, quibusdam ipsam
            cupiditate voluptates, repudiandae, consequuntur molestiae facere
            consequatur dolore quisquam quis ullam iusto!Lorem
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
            maxime amet officia. Qui natus mollitia cumque, quibusdam ipsam
            cupiditate voluptates, repudiandae, consequuntur molestiae facere
            consequatur dolore quisquam quis ullam iusto!Lorem
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
            maxime amet officia. Qui natus mollitia cumque, quibusdam ipsam
            cupiditate voluptates, repudiandae, consequuntur molestiae facere
            consequatur dolore quisquam quis ullam iusto!Lorem
          </p>
        </div>
        <div class="about_captions d-block d-sm-none">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
            maxime amet officia. Qui natus mollitia cumque, quibusdam ipsam
            cupiditate voluptates, repudiandae, consequuntur molestiae facere
            consequatur dolore quisquam quis ullam iusto!Lorem
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
            maxime amet officia. Qui natus mollitia cumque, quibusdam ipsam
            cupiditate voluptates, repudiandae, consequuntur molestiae facere
            consequatur dolore quisquam quis ullam iusto!Lorem
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
            maxime amet officia. Qui natus mollitia cumque, quibusdam ipsam
            cupiditate voluptates, repudiandae, consequuntur molestiae facere
            consequatur dolore quisquam quis ullam iusto!Lorem
          </p>
        </div>
        <div class="color-primary text-big about_subtitle">
          ความรู้พื้นฐานด้านวางแผนการเงิน
        </div>
        <div class="color-primary text-big about_subtitle">
          ความรู้พื้นฐานด้านวางแผนเกษียณอายุ
        </div>
        <div class="color-primary text-big about_subtitle">
          ความรู้พื้นฐานด้านวางแผนภาษีและมรดก
        </div>
        <div class="color-primary text-big about_subtitle">
          ความรู้พื้นฐานด้านวางแผนป้องกันความเสี่ยง
        </div>
        <p class="about_captions" style="padding-bottom: 40px;">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
          maxime amet officia. Qui natus mollitia cumque, quibusdam ipsam
          cupiditate voluptates, repudiandae, consequuntur molestiae facere
          consequatur dolore quisquam quis ullam iusto!Lorem
        </p>
      </div>
    </div>

    <div class="container" style="padding-top:40px">
      <div>
        <div class="noshadow_card">
          <h2 class="color-primary about_title title_font d-none d-sm-block">
            หลักสูตรนี้เหมาะสมกับใคร
          </h2>
          <h3 class="color-primary about_title d-block d-sm-none">
            หลักสูตรนี้เหมาะสมกับใคร
          </h3>
          <div
            class="about_captions d-none d-sm-block"
            style="padding-bottom: 40px;"
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
            maxime amet officia. Qui natus mollitia cumque, quibusdam ipsam
            cupiditate voluptates, repudiandae, consequuntur molestiae facere
            consequatur dolore quisquam quis ullam iusto!Lorem
          </div>
          <div class="d-block d-sm-none">
            <div style="margin:30px 5px 0px 5px; white-space: pre-line;">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
              maxime amet officia. Qui natus mollitia cumque, quibusdam ipsam
              cupiditate voluptates, repudiandae, consequuntur molestiae facere
              consequatur dolore quisquam quis ullam iusto!Lorem
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about_container">
      <div class="container row" style="padding-bottom: 40px;">
        <div
          v-for="(info, index) in infoList"
          v-bind:key="index"
          class="col-12 col-md-4 my-2"
        >
          <InfoCard :info="info" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="noshadow_card card3">
        <h2 class="color-primary about_title d-none d-sm-block">
          ค่าใช้จ่ายของผู้ใช้บริการ
        </h2>
        <h3 class="color-primary about_title d-block d-sm-none">
          ค่าใช้จ่ายของผู้ใช้บริการ
        </h3>
        <p class="about_captions d-none d-sm-block">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum animi
          dicta quis quisquam eius perspiciatis tempora sunt excepturi
          laboriosam provident! Hic debitis laborum fugit magnam facilis
          repudiandae, officiis ex nostrum? Lorem ipsum dolor sit amet
          consectetur, adipisicing elit. Aperiam saepe adipisci nobis debitis
          est atque ut nemo asperiores aut tempora? Porro id officia voluptates
          sit perspiciatis temporibus dolore itaque reprehenderit.
        </p>
        <p class="about_captions d-block d-sm-none">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
          maxime amet officia. Qui natus mollitia cumque, quibusdam ipsam
          cupiditate voluptates, repudiandae, consequuntur molestiae facere
          consequatur dolore quisquam quis ullam iusto!Lorem
        </p>
      </div>
    </div>
    <div class="container">
      <div class="noshadow_card card4">
        <p class="about_captions d-none d-sm-block">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit
          vero exercitationem quam porro temporibus, tempora architecto sequi
          delectus eaque dicta, voluptatibus est, esse facilis ex ipsam. Dolore
          quaerat esse a?Lorem ipsum dolor sit amet, consectetur adipisicing
          elit. Molestias eaque eos ea, quibusdam sit qui magni corrupti. Earum
          deleniti, dolor eaque illo quibusdam laborum ex, libero at ea, animi
          fugiat.
        </p>
        <p class="about_captions d-block d-sm-none">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum voluptas
          tenetur accusantium facere, dolores doloremque temporibus ducimus
          consectetur labore at rem perferendis nulla laboriosam inventore
          placeat error culpa sed dolor?
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import InfoCard from "../components/InfoCard.vue";
export default {
  name: "About",
  components: {
    InfoCard,
  },
  data() {
    return {
      infoList: [
        {
          infoId: 1,
          infoName: "ประชาชนทั่วไป",
          infoDesc:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum voluptas tenetur accusantium facere, dolores doloremque temporibus ducimus consectetur labore at rem perferendis nulla laboriosam inventore placeat error culpa sed dolor?",
          infoSrc: require("../assets/images/info_Card1.png"),
        },
        {
          infoId: 2,
          infoName: "บุคคลทั่วไปและนักศึกษาจบใหม่",
          infoDesc:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum voluptas tenetur accusantium facere, dolores doloremque temporibus ducimus consectetur labore at rem perferendis nulla laboriosam inventore placeat error culpa sed dolor?",
          infoSrc: require("../assets/images/info_Card2.png"),
        },
        {
          infoId: 3,
          infoName: "ที่ปรึกษาทางการเงิน",
          infoDesc:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum voluptas tenetur accusantium facere, dolores doloremque temporibus ducimus consectetur labore at rem perferendis nulla laboriosam inventore placeat error culpa sed dolor?",
          infoSrc: require("../assets/images/info_Card3.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.about_container {
  display: flex;
  justify-content: center;
}
.about_card {
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 30px 20px;
}

.noshadow_card {
  text-align: center;
  padding: 30px 20px;
}
.about_title {
  /* padding-top: 20px; */
  margin: 21px;
}
.title_font {
  font-size: 36px;
  font-weight: 700;
}
.about_subtitle {
  margin: 26px;
}
.about_captions {
  margin: 30px;
  width: 95%;
  margin: auto;
}

.card1 {
  position: relative;
  width: 100%;
  /* height: 255px; */
}
.card2 {
  width: 100%;
  height: auto;
}
.card3 {
  width: 100%;
  height: auto;
}
.card4 {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.bluebox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 22px;
  background-color: #07c5f8;
}

.thumbnail-container {
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-top: 33.33%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.thumbnail-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}
</style>
